import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {AppState} from '../context'
import {Redirect} from 'react-router-dom'

function Copyright() {
        return (
                <Typography variant="body2" color="textSecondary" align="center">
                        {'Copyright © '}
                        <Link color="inherit" href="https://watchbidzwebsite.web.app/">
                                Watchbidz
      </Link>{' '}
                        {new Date().getFullYear()}
                        {'.'}
                </Typography>
        );
}


const useStyles = makeStyles((theme) => ({
        root: {
                height: '100vh',
        },
        image: {
                backgroundImage: `url(${process.env.PUBLIC_URL+'/buyer.jpg'})`,
                backgroundRepeat: 'no-repeat',
                backgroundColor:
                        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
                backgroundSize: 'cover',
                backgroundPosition: 'center',
        },
        paper: {
                margin: theme.spacing(8, 4),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
        },
        avatar: {
                margin: theme.spacing(1),
                backgroundColor: theme.palette.secondary.main,
        },
        form: {
                width: '100%', // Fix IE 11 issue.
                marginTop: theme.spacing(1),
        },
        submit: {
                margin: theme.spacing(3, 0, 2),
        },
}));

export default function SignInSideBuyer() {
        const [error, setError] = useState(false);
        const [pass, setPass] = useState("");
        const [username, setUsername] = useState("");
        const someContext = React.useContext(AppState);
        const {user, setUser, setLogged, buyers} = someContext;
        const [redirect, setRedirect]=useState(false);
        function CheckPass() {
                console.log(username)
                console.log(pass)
                if (buyers[username]) {
                  if (buyers[username]["password"] === pass) {
                    setUser(username);
                //     if(user["role"]=="admin"){
                //             //redirect somewhere else
                //     }
                //     else{
                //             redirect llse
                //     }
                    setRedirect(true);
                  }
                }
                else if (pass && username) {
                    console.log("error")
                    setError(true)
                }
              }
        const classes = useStyles();

        return (
                <div>
                <Grid container component="main" className={classes.root}>
                        <CssBaseline />
                        <Grid item xs={false} sm={4} md={7} className={classes.image} />
                        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                                <div className={classes.paper}>
                                        <Avatar className={classes.avatar}>
                                                <LockOutlinedIcon />
                                        </Avatar>
                                        <Typography component="h1" variant="h5">
                                                Welcome
          </Typography>
          <Typography component="h1" variant="h5">
                                                Buyer Portal
          </Typography>
                                        <form className={classes.form} noValidate>
                                                <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        id="email"
                                                        label="Username"
                                                        name="email"
                                                        autoComplete="email"
                                                        autoFocus
                                                        onChange={(e)=>
                                                        setUsername(e.target.value)}
                                                />
                                                <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="password"
                                                        label="Password"
                                                        type="password"
                                                        id="password"
                                                        autoComplete="current-password"
                                                        onChange={(e)=>
                                                        setPass(e.target.value)}
                                                />
                                                <FormControlLabel
                                                        control={<Checkbox value="remember" color="primary" />}
                                                        label="Remember me"
                                                />
                                                <Button
                                                              onClick={()=>CheckPass()}
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.submit}
                                                >
                                                        Sign In
            </Button>
                                                <Grid container>
                                                        <Grid item xs>
                                                                <Link variant="body2">
                                                                        Forgot password? Email watchbidz@gmail.com
                </Link>
                                                        </Grid>
                                                        <Grid item>
                                                                <Link href="/buyer/signup" variant="body2">
                                                                        {"Don't have an account? Sign Up"}
                                                                </Link>
                                                        </Grid>
                                                </Grid>
                                                <Box mt={5}>
                                                        <Copyright />
                                                </Box>
                                        </form>
                                </div>
                        </Grid>
                </Grid>
                {user  ? <Redirect to={'/buyer/home'} /> : ""}
                </div>
        );
}