import firebase from 'firebase/app';
import 'firebase/database';
import apikey from './apiKey';
import 'firebase/storage'

const firebaseConfig = {
  apiKey: apikey,
  authDomain: "watchbidzwebsite.firebaseapp.com",
  databaseURL: "https://watchbidzwebsite.firebaseio.com",
  projectId: "watchbidzwebsite",
  storageBucket: "watchbidzwebsite.appspot.com",
  messagingSenderId: "235902043701",
  appId: "1:235902043701:web:f06b1a4a25cdc553e3137c",
  measurementId: "G-047GL4F8PG"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
export default storage;
export const dbSellers = firebase.database().ref().child('sellers');
export const dbBuyers = firebase.database().ref().child('buyers');
export const dbListed = firebase.database().ref().child('listed');
export const dbAuctions = firebase.database().ref().child('auction');
export const dbPrevious = firebase.database().ref().child('previous');
export const dbAdmins = firebase.database().ref().child('admin');
export const dbRecentlyClosed = firebase.database().ref().child('recentlyclosed');
export const dbIncompleteDeals = firebase.database().ref().child('incompletedeals');
