import HeaderBar from "./HeaderBar";
import { List } from "@material-ui/core";
import React , {useContext, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box'
import ListedList from './homepage_components/ListedList'
import Divider from '@material-ui/core/Divider'
import {Container} from 'semantic-ui-react'
import ListWatchModal from './Modal/ListWatchModal'
import RecentlySold from '../RecentlySold'
import {AppState} from '../context'
import { Redirect } from "react-router-dom";
import Waiting from '../Waiting'
const useStyles = makeStyles((theme) => ({
        container: {
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          gridGap: theme.spacing(3),
        },
        paper: {
          padding: theme.spacing(1),
          textAlign: 'center',
          color: theme.palette.text.secondary,
          whiteSpace: 'nowrap',
          marginBottom: theme.spacing(1),
        },
        divider: {
          margin: theme.spacing(2, 0),
        },
      }));

const Homepage = () => {
        const[wait,setWait]=useState(true)
        setTimeout(()=>(setWait(false)),2000)
        const classes = useStyles();
        const { sellers, user } = React.useContext(AppState);
        if(user && sellers[user]){
                return (
                        <div>
                                <Box style={{marginLeft:"10px"}}>
                                <ListWatchModal  />
                                <Grid container
                                        direction="row"
                                        justify="center"
                                        alignItems="baseline"
                                        margin 
                                        style={{marginLeft:"10px"}}
                                        >
                                        <Grid item xs={5}>
                                                <Typography variant="h4" gutterBottom >
                                                        Your Listed Watches
                                                </Typography>
                                        </Grid>
                                </Grid>
                
                                <Grid container
                                        direction="row"
                                        justify="center"
                                        alignItems="baseline"
                                        margin
                                        style={{marginLeft:"10px", height:'300px'}}
                
                                        >
                                        <Grid item xs={5} >
                                                <ListedList />
                                        </Grid>
                                </Grid>
                                <Grid container
                                        direction="row"
                                        justify="center"
                                        alignItems="baseline"
                                        margin 
                                        style={{marginTop:"20px",
                                                marginLeft:"10px"}}
                                        >
                                        <Grid item xs={5}>
                                                <Typography variant="h4" gutterBottom >
                                                        Recently Closed Deals - Hot Watches
                                                </Typography>
                                        </Grid>
                                </Grid>
                
                                <Grid container
                                        direction="row"
                                        justify="center"
                                        alignItems="baseline"
                                        margin
                                        style={{marginLeft:"10px"}}
                                        >
                                        <Grid item xs={5}>
                                                <RecentlySold />
                                        </Grid>
                                </Grid>
                                </Box>
                                
                
                        </div>);                
        }
        else if(wait){
                return(
                <Waiting />
                )
        }
        else{
                return(<Redirect to='/seller/login' />)
        }
        
}
export default Homepage;